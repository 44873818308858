






































































































































































import isEqual from 'lodash/isEqual'
import { Location } from 'vue-router'
import { proposalSortOptions } from '@/data/proposals'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ProcessResource } from '@/models/process/ProcessResource'
import { ProposalSummaryCollectionResource } from '@/models/proposals/ProposalSummaryCollectionResource'
import { mixin as clickaway } from '@/plugins/vue-clickaway'

import AddPlaceholder from './AddPlaceholder.vue'
import Modal from '@/components/widgets/Modal.vue'
import DropdownSort from '@/components/inputs/DropdownSort.vue'
import SearchInput from '@/components/inputs/Search.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ProposalSummary from '@/components/tiles/ProposalSummary.vue'
import DateInput from '@/components/inputs/Date.vue'

import { StatusResourceCollection } from '@/models/status/StatusResourceCollection'
import { projectModule } from '@/store'
import {ProposalIndexRequest} from '@/requests/proposals/ProposalIndexRequest'
import cloneDeep from 'lodash/cloneDeep'

const DEFAULT_PARAMS: ProposalIndexRequest = {
  status: [],
  page: '1',
  coverage: undefined,
  search: '',
  column: 'created_at',
  dir: 'desc',
  parent_ids: [],
  child_ids: [],
  component_values: {}
}

// component
@Component({
  components: {
    Modal,
    SmallLoader,
    DropdownSort,
    AddPlaceholder,
    ProposalSummary,
    SearchInput,
    DropdownInput,
    DateInput
  },
  mixins: [clickaway],
})
export default class ConnectionLane extends Vue {
  // props
  @Prop()
  private process!: ProcessResource

  @Prop({ default: null })
  private selectedId!: number

  @Prop({ default: null })
  private selectedIds!: number[]

  @Prop()
  private tags!: any

  // data
  private loading: boolean = false
  private showFilters: boolean = false

  private sortOptions: SelectItem[] = proposalSortOptions
  private proposals: ProposalSummaryCollectionResource[] = []
  private filters: ProposalIndexRequest = {...DEFAULT_PARAMS}
  private defaultFilters: ProposalIndexRequest = {...DEFAULT_PARAMS}
  private proposalFilters: { [key: string]: { [key: string]: string } } = {}

  private get previousProcess(): ProcessResource | null {
    return projectModule.getProcessByOrder((this.process?.order ?? 0) - 1 ?? 0)
  }

  private get nextProcess(): ProcessResource | null {
    return projectModule.getProcessByOrder((this.process?.order ?? 0) + 1 ?? 0)
  }

  private get previousProcessName(): string {
    return this.previousProcess?.name ?? ''
  }

  private get nextProcessName(): string {
    return this.nextProcess?.process_name ?? ''
  }

  private get filtersActive(): boolean {
    return !isEqual({ ...this.filters }, this.defaultFilters)
  }

  private get notSelectedProposals(): ProposalSummaryCollectionResource[] {
    return this.proposals.filter((proposal) => {
      return !(this.isConnected(proposal) || this.isSelected(proposal))
    })
  }

  private get selectedProposals(): ProposalSummaryCollectionResource[] {
    return this.proposals.filter((proposal) => {
      return this.isConnected(proposal) || this.isSelected(proposal)
    })
  }

  private get statusFilters(): Array<Partial<StatusResourceCollection>> {
    let filters: Array<Partial<StatusResourceCollection>> = []

    if (this.process && typeof this.process.statuses === 'object') {
      filters = [...this.process.statuses]
    }

    return filters
  }

  private get overviewRoute(): Location {
    return {
      name: `projects-detail-process`,
      params: {
        project_id: this.process.project_id.toString(),
        process_id: this.process.id.toString(),
      },
    }
  }

  private get parentFilters(): SelectItem[] {
    if (this.proposalFilters?.parents && this.previousProcess) {
      const FILTERS = [
        {
          value: 'NA',
          label: `No ${this.previousProcessName} connected`,
        },
      ]

      Object.keys(this.proposalFilters.parents).map((key: string) => {
        FILTERS.push({
          value: key,
          label: this.proposalFilters.parents[key],
        })
      })

      return FILTERS
    }

    return []
  }

  private get childFilters(): SelectItem[] {
    if (this.proposalFilters?.children && this.nextProcess) {
      const FILTERS = [
        {
          value: 'NA',
          label: `No ${this.nextProcessName} connected`,
        },
      ]

      Object.keys(this.proposalFilters.children).map((key: string) => {
        FILTERS.push({
          value: key,
          label: this.proposalFilters.children[key],
        })
      })

      return FILTERS
    }

    return []
  }

  @Watch('selectedId')
  private onSelectedIdChange(val: number | null) {
    if (typeof val === 'number') {
      // Wait for proposals to be collapsed otherwise scrolling will be canceled
      this.$nextTick(() => {
        this.scrollTop()
      })
    }
  }

  private setProposalParam(key: string, value: string): void {
    this.filters[key] = value
  }

  private isSelected(proposal: ProposalSummaryCollectionResource): boolean {
    return this.selectedId === proposal.id
  }

  private isConnected(proposal: ProposalSummaryCollectionResource): boolean {
    return this.selectedIds?.includes(proposal.id)
  }

  private notSelected(proposal: ProposalSummaryCollectionResource): boolean {
    // Only when something is selected we lower the opacity
    if (this.selectedId) {
      return !(this.isSelected(proposal) || this.isConnected(proposal))
    }
    return false
  }

  private setSortParam(sort: string): void {
    const [column, dir] = sort.split('-')

    this.setProposalParam('column', column)
    this.setProposalParam('dir', dir)

    this.applyFilters()
  }

  private applyFilters(): void {
    this.getData()
    if (this.showFilters) {
      this.toggleFilters()
    }
  }

  // dispatch
  private async created(): Promise<void> {
    // fetch data
    await this.getData()
    this.setInitialFilters()
  }

  private async getData(): Promise<void> {
    this.loading = true
    try {
      const data = await this.process.getProposalsIndex(this.filters)
      const updated = data.data.map((item: any) => {
        item.metaTags = this.tags[item.document_id]
        return item
      })
      
      this.$set(this, 'proposals', updated)
      this.$set(this, 'proposalFilters', data.filters)
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  }

  private async clearFilters(): Promise<void> {
    this.filters = { ...DEFAULT_PARAMS, component_values: {} }

    if (this.showFilters) {
      this.toggleFilters()
    }
    await this.getData()
    this.setInitialFilters()
  }

  private toggleFilters(): void {
    this.showFilters = !this.showFilters
  }

  private closeFilters(): void {
    this.showFilters = false
  }

  private selectProposal(proposal: ProposalSummaryCollectionResource): void {
    this.$emit('selectProposal', proposal)
  }

  private scrollTop(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    wrapper?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  private setInitialFilters() {
    for (const { id } of this.process.componentFilters) {
      if (this.filters.component_values) {
        Vue.set(this.filters.component_values, id, [])
      }
    }
    // @ts-ignore
    for (const { id } of this.proposalFilters?.components ?? []) {
      if (this.filters.component_values) {
        Vue.set(this.filters.component_values, id, [])
      }
    }

    this.defaultFilters = cloneDeep(this.filters)
  }
}
